import React from "react";

import Layout from "@components/Layout";
import Seo from "@components/Seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="not-found-container">
      <h1 className="not-found-txt">🤔</h1>
      <p>
        <strong>페이지를 찾을 수 없습니다...</strong>
      </p>
      <p>찾으시는 페이지가 삭제되었나봐요</p>
    </div>
  </Layout>
);

export default NotFoundPage;
